import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IgioService {

  constructor(private httpClient: HttpClient) { }

  verifyEmail(userId: string, emailAddress: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(emailAddress);
    return this.httpClient.post(`${environment.apiHost}api/IgioUser/VerifyUserEmail/${userId}`, body, httpOptions);
  }

  unsubscribe(userId: string, emailAddress: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(emailAddress);
    return this.httpClient.post(`${environment.apiHost}api/IgioUser/UnsubscribeUserMailingList/${userId}`, body, httpOptions);
  }

  validateUserResetPasswordUniqueId(uniqueId: string, emailAddress: string) {
    return this.httpClient.get(`${environment.apiHost}api/Auth/ValidateUserResetPasswordUniqueId/${emailAddress}/${uniqueId}`);
  }

  getImagesByContainer(container: string) {
    return this.httpClient.get(`${environment.apiHost}api/IgioImageUpload/getImagesByContainer/${container}`);
  }

  deleteBlobImage(imageUrl: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.delete(`${environment.apiHost}api/IgioImageUpload/deleteBlobImage/${imageUrl}`, httpOptions);
  }

  changePassword(userId: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(password);
    return this.httpClient.put(`${environment.apiHost}api/Auth/${userId}/ChangePassword`, body, httpOptions);
  }

}
