import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';


import { IgioService } from '../igio.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  uniqueId: string;
  emailAddress: string;
  userId: string;
  errorMsg: string;
  canChangePassword = false;
  success: boolean;
  changePasswordForm: FormGroup;

  constructor(private route: ActivatedRoute, private igioService: IgioService, public formBuilder: FormBuilder) {
    this.changePasswordForm = formBuilder.group({
      password: ['', Validators.compose([Validators.maxLength(20), Validators.required])],
      confirmpassword: ['', Validators.compose([Validators.maxLength(20), Validators.required])]
    });
  }

  ngOnInit() {
    this.uniqueId = this.route.snapshot.paramMap.get('uniqueid');
    this.emailAddress = this.route.snapshot.paramMap.get('email');
    this.igioService.validateUserResetPasswordUniqueId(this.uniqueId, this.emailAddress).subscribe((result: any) => {
      if (!result.success) {
        this.errorMsg = result.resultData;
      } else {
        this.errorMsg = '';
        this.canChangePassword = true;
        this.userId = result.resultData;
      }
    });
  }

  onChangePassword() {
    console.log('change password clicked ', this.changePasswordForm.valid);
    this.errorMsg = '';
    const password = this.changePasswordForm.get('password').value;
    const confirmpassword = this.changePasswordForm.get('confirmpassword').value;
    if (!password) {
      return;
    }
    if (password !== confirmpassword) {
      this.errorMsg = 'Your passwords don`t match.';
    }

    this.igioService.changePassword(this.userId, password).subscribe(res => {
      if (res === true) {
        this.success =  true;
      }
    });

  }

}
