import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { CookiesComponent } from './cookies/cookies.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { IgioService } from './igio.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DownloadappComponent } from './downloadapp/downloadapp.component';
import { ManageGalleryComponent } from './manage-gallery/manage-gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PrivacyComponent,
    TermsComponent,
    CookiesComponent,
    VerifyEmailComponent,
    UnsubscribeComponent,
    ChangePasswordComponent,
    DownloadappComponent,
    ManageGalleryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [IgioService],
  bootstrap: [AppComponent]
})
export class AppModule { }
