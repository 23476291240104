import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IgioService } from '../igio.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  id: string;
  email: string;
  msg: string;
  failed: boolean;

  constructor(private route: ActivatedRoute, private igioService: IgioService) { }

  ngOnInit() {
    this.msg = 'Please wait...';
    this.id = this.route.snapshot.paramMap.get('id');
    this.email = this.route.snapshot.paramMap.get('email');
    this.failed = false;
    this.igioService.verifyEmail(this.id, this.email).subscribe((data: any) => {
      if (data.success) {
        this.msg = 'Thanks for verifying your email';
      } else {
        this.msg = 'Unable to verify your email.';
        this.failed = true;
      }
    });

  }

}
