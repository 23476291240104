import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IgioService } from '../igio.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  id: string;
  email: string;
  msg: string;
  constructor(private route: ActivatedRoute, private igioService: IgioService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.email = this.route.snapshot.paramMap.get('email');
  }

  unsubscribe() {
    this.igioService.unsubscribe(this.id, this.email).subscribe((data: any) => {
      if (data.success) {
        this.msg = 'Your request was sucessful.';
      }
    });
  }

}
