import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './cookies/cookies.component';
import { TermsComponent } from './terms/terms.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DownloadappComponent } from './downloadapp/downloadapp.component';
import { ManageGalleryComponent } from './manage-gallery/manage-gallery.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  { path: 'home', component: HomeComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'getapp', component: DownloadappComponent },
  { path: 'managegallery', component: ManageGalleryComponent },
  { path: 'changepassword/:uniqueid/:email', component: ChangePasswordComponent },
  { path: 'unsubscribe/:id/:email', component: UnsubscribeComponent },
  { path: 'verifyemail/:id/:email', component: VerifyEmailComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
