import { Component, OnInit } from '@angular/core';
import { IgioService } from '../igio.service';

@Component({
  selector: 'app-manage-gallery',
  templateUrl: './manage-gallery.component.html',
  styleUrls: ['./manage-gallery.component.scss']
})
export class ManageGalleryComponent implements OnInit {

  galleryImages: string[];
  container: string;
  isLoading = false;
  constructor(private igioService: IgioService) { }

  ngOnInit() {
    this.loadImages();
  }

  onContainerChanged(e) {
    console.log(e, e.target.value);
    this.loadImages(e.target.value);
  }

  loadImages(container: string = 'Image') {
    this.isLoading =  true;
    this.igioService.getImagesByContainer(container).subscribe((images: any) => {
      this.galleryImages = images.resultData;
      this.isLoading = false;
    });
  }

  deleteBlob(img: string) {
    const image = img.split('/').pop();
    // console.log(ima.pop());
    this.igioService.deleteBlobImage(image).subscribe(res => {
      console.log(res);
      this.loadImages();
    });
  }

}
